<template>
  <div
    class="wrapper"
    ref="wrapper"
  >
    <div
      class="content"
      :style="{display:inline ? 'inline-block':''}"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import BScroll from '@better-scroll/core';
import Pullup from '@better-scroll/pull-up';
import { Debounce } from '@/common/utils'
BScroll.use(Pullup)
export default {
  props: {
    probeType: {
      type: Number,
      default: 0, //父组件传过3来
    },
    pullUpLoad: {
      type: Boolean,
      default: false, //默认不需要上拉
    },
    scrollX: {
      type: Boolean,
      default: false, //默认不开启
    },
    inline: {
      type: Boolean,
      default: false,
    },
    mouseWheel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    scrollbar: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      scroll: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.scroll = new BScroll(this.$refs.wrapper, {
        scrollX: this.scrollX,
        click: true,
        pullingUp: true,
        //是否阻止事件冒泡。多用在嵌套 scroll 的场景
        stopPropagation: true,
        probeType: this.probeType, //监听滚动距离,this.probeType指向每个组件传过来的数值,0是不监听滚动的只有2和3会监听
        pullUpLoad: this.pullUpLoad, //默认不需要上拉。但是Home父组件传过来了一个true，Home就有是true
        useTransition: false, //better-scroll在IOS 13.4版本上快速滑动，回出现异常回弹效果。这是由于IOS版本导致的。
        swipeBounceTime: 200,//滚动时间
        mouseWheel: this.mouseWheel,
        scrollbar: this.scrollbar,
      });
      //2.监听滚动的位置
      if (this.probeType === 1 || this.probeType === 2 || this.probeType === 3) {
        this.scroll.on("scroll", (position) => {
          this.$emit("scroll", position); //发射滚动事件,父组件需要用@scroll，$emit('注册的名字',参数)
        });
      }
      //3.监听scroll滚到底部
      if (this.pullUpLoad) {
        this.scroll.on("pullingUp", () => {
          this.$emit("pullingUp"); //发射注册的事件
        });
      }
      //结束滚动时候
      this.scroll.on('scrollEnd', () => {
        this.$emit("scrollEnd"); //发射注册的事件
      })
    })
  },
  //3.监听上拉事件
  methods: {
    scrollTo (x, y, time = 300) {
      //封装了一个上箭头,scrollTo滚动到x和y坐标轴，time是设置多久滚到
      this.scroll && this.scroll.scrollTo(x, y, time);
    },
    scrollToElement (el, time = 1000) {
      //滚到到指定dom元素位置
      this.scroll && this.scroll.scrollToElement(el, time)
    },
    finishPullUp: Debounce(function () {
      this.scroll && this.scroll.finishPullUp(); //上拉加载更多,再进行更多的加载，fininshpullUp是插件里面的方法,
    }, 1000),
    refresh () {
      this.scroll && this.scroll.refresh(); //scroll.refresh()是插件的重新计算高度的固定语法
    },
    enable () {
      this.scroll && this.scroll.enable();
    },
    getScrollY () {
      //判断this.scroll有没有值
      return this.scroll ? this.scroll.y : 0;
    },
  },
}
</script>

<style>
</style>